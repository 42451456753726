<template>
  <div class="works">
    <left-menu current="demo-list" />
    <div class="main">

      <el-row>
        <el-col :span="12">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/demo-list">词曲作品列表</el-breadcrumb-item>
            <el-breadcrumb-item :to="'/demo-view/' + id">作品详情</el-breadcrumb-item>
            <el-breadcrumb-item>合同详情</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="12" style="text-align: right"><el-link type="primary" @click="$router.back()"> &lt;返回</el-link></el-col>
      </el-row>

      <el-divider></el-divider>

      <h4>基本信息</h4>
      <div class="info">
        <span class="label">合同编号</span>
        <span class="text">{{ contract.id }}</span>
      </div>
      <div class="info">
        <span class="label">合同类型</span>
<!--        1-全曲库代理(入驻) 2-自由曲库代理(入驻) 3-代售约(作品) 4-采购约(作品) 5-作品回购协议 6-歌曲转让协议 7-下架同意函-->
        <span class="text">{{ ['', '全曲库代理', '自由曲库代理', '代售约', '采购', '作品回购协议', '歌曲转让协议', '下架同意函'][contract.contractType] }}</span>
      </div>
      <div class="info">
        <span class="label">签约方式</span>
<!--        签约方式：0-线下签约 1-线上签约-->
        <span class="text">{{ ['线下签约', '线上签约'][contract.signType] }}</span>
      </div>
      <div class="info">
        <span class="label">合同状态</span>
        <span class="text">{{ ['', '新增', '待他人签署', '签署完成', '已取消'][this.contract.status] }}</span>
      </div>
      <div class="info">
        <span class="label">合同名称</span>
        <span class="text" style="color: #FF004D">{{ contract.title }}</span>
      </div>
      <div class="info">
        <span class="label">发起时间</span>
        <span class="text">{{ $timestampToString(Number(contract.createTime)) }}</span>
      </div>
      <div class="info">
        <span class="label">完成时间</span>
        <span class="text">{{ $timestampToString(Number(contract.completeTime)) }}</span>
      </div>

      <div style="width: 100%;text-align: center;margin-top: 64px">
        <el-button class="big-button" type="info" @click="$router.back()">返回</el-button>
        <el-button class="big-button" type="primary" @click="downloadContract()">下载合同</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from '../components/LeftMenu.vue'
import api from '../api'
export default {
  name: "Contract",
  props: ['id'],
  components: {
    LeftMenu
  },
  data() {
    return {
      contract: {
        id: '',
        contractType: 0,
        signType: 0,
        createTime: '',
        completeTime: ''
      }
    }
  },
  mounted() {
    this.getContract()
  },
  methods: {
    getContract() {
      api.getContractById(this.id).then(r => {
        this.contract = r.data
      })
    },
    base64ToBlob(code) {
      //Base64一行不能超过76字符，超过则添加回车换行符。因此需要把base64字段中的换行符，回车符给去掉，有时候因为存在需要把加号空格之类的换回来，取决于base64存取时的规则。
      code = code.replace(/[\n\r]/g, '');
      var raw = window.atob(code);
      let rawLength = raw.length;
      //转换成pdf.js能直接解析的Uint8Array类型
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], {type: 'application/pdf'});//转成pdf类型
    },
    downloadContract() {
      api.download('/api/demo/contract/File?contractId=' + this.contract.id).then(response => {
        // 提取文件名
        // const filename = response.headers['content-disposition'].match(
        //     /filename=(.*)/
        // )[1]
        const filename = this.contract.contractFile
        // 将二进制流转为blob
        const blob = this.base64ToBlob(response)
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
          window.navigator.msSaveBlob(blob, decodeURI(filename))
        } else {
          // 创建新的URL并指向File对象或者Blob对象的地址
          const blobURL = window.URL.createObjectURL(blob)
          // 创建a标签，用于跳转至下载链接
          const tempLink = document.createElement('a')
          tempLink.style.display = 'none'
          tempLink.href = blobURL
          tempLink.setAttribute('download', decodeURI(filename))
          // 兼容：某些浏览器不支持HTML5的download属性
          if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
          }
          // 挂载a标签
          document.body.appendChild(tempLink)
          tempLink.click()
          document.body.removeChild(tempLink)
          // 释放blob URL地址
          window.URL.revokeObjectURL(blobURL)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../styles/works.scss';
.info{
  .label{
    display: inline-block;
    text-align-last: justify;
    width: 56px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 32px;
    margin-right: 18px;
  }
  .text{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
}
</style>
